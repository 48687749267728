import React from "react";
import { useQuery } from "react-fetching-library";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ArrowForward as ArrowIcon } from "@material-ui/icons";

import { fetchCategoryTags } from "resources/api";
import { ITag } from "resources/interface";
import { ActionType } from "resources/constants";
import { useLocationContext } from "resources/hooks";

import cat1 from "assets/categories/1.jpg";
import cat2 from "assets/categories/2.jpg";
import cat3 from "assets/categories/3.jpg";
import cat4 from "assets/categories/4.jpg";
import cat5 from "assets/categories/5.jpg";
import cat6 from "assets/categories/6.jpg";
import cat7 from "assets/categories/7.jpg";
import cat8 from "assets/categories/8.jpg";
import cat9 from "assets/categories/9.jpg";
import cat10 from "assets/categories/10.jpg";
import cat11 from "assets/categories/11.jpg";
import cat12 from "assets/categories/12.jpg";

interface ICat {
  name: string;
  image: string;
  tag?: ITag;
}

const categories: Array<ICat> = [
  {
    name: "Houses / Hotels / Campsites",
    image: cat1,
  },
  {
    name: "Schools / Universities",
    image: cat2,
  },
  {
    name: "Historical / Government / Religious",
    image: cat3,
  },
  {
    name: "Shopping / Entertainment / Leisure",
    image: cat4,
  },
  {
    name: "Restaurants / Bars / Nightclubs",
    image: cat5,
  },
  {
    name: "Studios / Warehouses / Industrial",
    image: cat6,
  },
  {
    name: "Offices",
    image: cat7,
  },
  {
    name: "Medical",
    image: cat8,
  },
  {
    name: "Sport",
    image: cat9,
  },
  {
    name: "Travel",
    image: cat10,
  },
  {
    name: "Multisites / Unitbases",
    image: cat11,
  },
  {
    name: "Parks / Countryside / Coastal",
    image: cat12,
  },
];

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  box: {
    position: "relative",
    cursor: "pointer",
  },
  image: {
    width: "100%",
    [breakpoints.up("md")]: {
      height: "19.2vw",
      objectFit: "cover",
    },
    marginBottom: "-8px",
  },
  button: {
    width: "fit-content",
    padding: 0,
    borderRadius: 0,
    marginRight: "20px",
  },
  greenBox: {
    position: "absolute",
    bottom: 0,
    height: "40px",
    backgroundColor: palette.primary.main,
    width: "100%",
    opacity: 0.8,
  },
  textBox: {
    position: "absolute",
    bottom: 0,
    padding: "5px 10px",
    color: palette.common.white,
    "& h6": {
      color: palette.common.white,
      fontSize: "16px",
    },
  },
  icon: {
    marginRight: "10px",
    backgroundColor: palette.common.white,
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    "& svg": {
      fill: palette.primary.main,
      width: "15px",
      marginTop: "-2px",
    },
  },
}));

const Category = ({ name, image, tag }: ICat) => {
  const classes = useStyles();
  const { dispatch } = useLocationContext();

  return (
    <Box
      className={classes.box}
      onClick={() => {
        if (tag) {
          window.scrollTo(0, 0);
          dispatch({
            type: ActionType.addFilterTag,
            selectedTag: tag,
          });
        }
      }}
    >
      <>
        <img src={image} alt={name} className={classes.image} />
        <Box className={classes.greenBox} />
        <Box className={classes.textBox}>
          <IconButton aria-label={name} className={classes.button}>
            <Box className={classes.icon}>
              <ArrowIcon />
            </Box>
            <Typography variant="subtitle2">{name}</Typography>
          </IconButton>
        </Box>
      </>
    </Box>
  );
};

const Categories = () => {
  const { payload: tags } = useQuery(fetchCategoryTags);

  return (
    <>
      {categories
        .map((cat) => {
          const tag = tags?.find(
            ({ filter_item_title }) => filter_item_title === cat.name
          );

          if (tag) return { ...cat, tag };
          return cat;
        })
        .map((cat) => (
          <Grid item xs={12} md={4} key={cat.name}>
            <Category {...cat} />
          </Grid>
        ))}
    </>
  );
};

export default Categories;
